import { useTranslation } from 'next-i18next';

export const DisplayBizzStatus = ({ status }: { status: string }) => {
  const { t } = useTranslation(['account']);

  const statusColors = (status: string) => {
    switch (status) {
      case 'blocked':
        return 'bg-red-50 text-red-700';
      case 'closed':
        return 'bg-red-50 text-red-700';
      case 'open':
        return 'bg-green-50 text-green-800';
      case 'pending':
        return 'bg-yellow-50 text-yellow-800';
      default:
        return 'Missing string';
    }
  };

  return (
    <div
      className={`flex w-fit items-center justify-center rounded-xl px-2 text-xs font-medium ${statusColors(
        status
      )}`}
    >
      {t(`kmtoll.landing.bizzList.bizzStatus.${status}`)}
    </div>
  );
};
