import { FormStoreProvider } from '@oresundsbron/use-form';
import { Children, FC, PropsWithChildren, useEffect, useMemo } from 'react';
import { Modal, ModalProps } from './Modal';
import {
  MultiStepProvider,
  MutliStepProviderProps,
  useMultiStepModalContext,
} from '../MultiStepProvider';
import { filter, size, lookup, mapWithIndex, head } from 'fp-ts/lib/Array';
import { pipe } from 'fp-ts/lib/function';
import { alt, toUndefined } from 'fp-ts/lib/Option';
import { Steps } from '@oresundsbron/bridge-ui';

const childrenToArray = (children: PropsWithChildren['children']) =>
  pipe(Children.toArray(children), filter(Boolean));

const MultiStep: FC<PropsWithChildren<{ hideProgress?: boolean }>> = ({
  hideProgress,
  children,
}) => {
  const { currentStep, setSteps } = useMultiStepModalContext();

  const childrenArr = useMemo(
    () => pipe(children, childrenToArray),
    [children]
  );

  useEffect(() => {
    setSteps(pipe(childrenArr, size));
  }, [childrenArr, setSteps]);

  const currentChild = useMemo(
    () =>
      pipe(
        childrenArr,
        lookup(currentStep),
        alt(() => pipe(childrenArr, head)),
        toUndefined
      ),
    [childrenArr, currentStep]
  );

  const steps = useMemo(
    () =>
      pipe(
        childrenArr,
        mapWithIndex((i) => (
          <Steps.Item
            key={i}
            nr={i + 1}
            active={currentStep >= i}
            completed={currentStep > i}
          />
        ))
      ),
    [childrenArr, currentStep]
  );

  return (
    <FormStoreProvider>
      <>
        {hideProgress ? null : (
          <Steps.Root direction="horizontal" className="mb-8">
            {steps}
          </Steps.Root>
        )}
        {currentChild}
      </>
    </FormStoreProvider>
  );
};

const Mod: FC<ModalProps & { hideProgress?: boolean }> = ({
  hideProgress,
  children,
  ...rest
}) => {
  const { close } = useMultiStepModalContext();
  return (
    <Modal
      {...rest}
      onOpenChange={(open) => {
        if (!open) {
          close();
        }
      }}
    >
      <MultiStep hideProgress={hideProgress}>{children}</MultiStep>
    </Modal>
  );
};

export const MultiStepModal: FC<
  ModalProps &
    Pick<MutliStepProviderProps, 'onClose'> & { hideProgress?: boolean }
> = ({ onClose, ...rest }) => {
  return (
    <MultiStepProvider onClose={onClose}>
      <Mod {...rest} />
    </MultiStepProvider>
  );
};
