import { useTypeSafeMutation } from '@oresundsbron/api';
import { useCallback } from 'react';
import { useCustomerInformation } from './useCustomerInformation';

interface ReturnError {
  error: string;
}

export function useUpdateSessionCustomer() {
  const { updateCustomer } = useCustomerInformation();
  const { mutateAsync } = useTypeSafeMutation(
    ['useUpdateSessionCustomer'],
    ({ Customer }) => Customer.updateSessionCustomer
  );

  const updateSessionCustomer = useCallback(
    async (customerNo: string): Promise<void | ReturnError> => {
      try {
        const newCustomer = await mutateAsync({ customerNo });
        await updateCustomer(newCustomer);
      } catch (error) {
        return { error: (error as Error).message };
      }
    },
    [mutateAsync, updateCustomer]
  );

  return updateSessionCustomer;
}
