import { Box, Spinner } from '@oresundsbron/design-system';
import { useTranslation } from 'next-i18next';

export const Loading = () => {
  const { t } = useTranslation('common');
  return (
    <Box
      display="flex"
      p="spacing.500"
      sx={{ justifyContent: 'center', alignItems: 'center' }}
    >
      <Box sx={{ width: '35px' }}>
        <Spinner aria-label={t('action.loading')} />
      </Box>
    </Box>
  );
};
