import { useTranslation } from 'next-i18next';

export const SelectedCompany = () => {
  const { t } = useTranslation(['common', 'account'], { nsMode: 'fallback' });
  return (
    <div className="bg-primary-600 mr-2 rounded-md px-2 py-1 text-white">
      {t('corporateGroup.selectedCompany')}
    </div>
  );
};
