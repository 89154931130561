import { ArrowRightLeft } from '@oresundsbron/icons';
import { DisplayActiveCorporate } from '../DisplayActiveCorporate';
import { Modal } from '~/components/Modal';
import { useState } from 'react';
import { CorporateGroup } from '../CorporateGroup';
import { useTranslation } from 'next-i18next';

export const CorporateGroupSideMenu = () => {
  const { t } = useTranslation(['account']);
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div className=" md:text-neutral-950 flex flex-col pt-8 text-white md:mb-8 md:pt-0">
        <div
          className="mb-1 flex cursor-pointer items-center font-semibold "
          onClick={() => setShowModal(true)}
        >
          <ArrowRightLeft className="mr-2" />
          {t('corporateGroup.changeActiveCorporate')}
        </div>
        <DisplayActiveCorporate className="flex flex-col" hideCustomerNo />
      </div>
      <Modal open={showModal} title="" onOpenChange={() => setShowModal(false)}>
        <CorporateGroup onSuccess={() => setShowModal(false)} />
      </Modal>
    </>
  );
};
