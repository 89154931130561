import { ArrowUp } from '@oresundsbron/icons';
import { CorporateGroupCustomer } from '@oresundsbron/validators';
import { useTranslation } from 'next-i18next';
import { SelectedCompany } from './SelectedCompany';

interface CorporateGroupListItemProps {
  corporate: CorporateGroupCustomer;
  index: number;
  isLoading?: boolean;
  errorIndex?: number;
  handleUpdateSessionCustomer: (
    customerNo: string,
    index: number
  ) => Promise<void>;
  activeCustomerNo?: string;
}

export const CorporateGroupListItem = ({
  corporate,
  index,
  handleUpdateSessionCustomer,
  errorIndex,
  isLoading,
  activeCustomerNo,
}: CorporateGroupListItemProps) => {
  const { t } = useTranslation(['common', 'account'], { nsMode: 'fallback' });
  return (
    <div
      className="flex max-h-min items-center border-[0.5px] border-neutral-300 p-4 text-sm font-semibold hover:cursor-pointer hover:bg-neutral-50"
      key={corporate.customerNo}
      onClick={() => handleUpdateSessionCustomer(corporate.customerNo, index)}
    >
      {isLoading ? (
        <div className="flex flex-grow font-thin">{t('action.loading')}</div>
      ) : (
        <div className="flex flex-grow flex-col">
          <div className="flex items-center justify-between ">
            <div className="flex items-center">
              {activeCustomerNo === corporate.customerNo && <SelectedCompany />}
              {corporate.name}, {corporate.customerNo}
            </div>
          </div>
          {errorIndex === index && (
            <div className="mt-2 w-full rounded bg-red-50 p-2 text-red-700">
              {t('corporateGroup.missingContract')}
            </div>
          )}
        </div>
      )}
      <ArrowUp className="text-primary-700 ml-2 rotate-90" />
    </div>
  );
};
