import { useState, useEffect } from 'react';
import { Search } from '@oresundsbron/icons';

interface SearchInputProps {
  label: string;
  description?: string;
  className?: string;
  placeholder?: string;
  query?: string;
  setQuery: (query: string) => void;
  maxLength?: number;
}

const SearchInput: React.FC<SearchInputProps> = ({
  label,
  description,
  placeholder,
  className,
  setQuery,
  query,
  ...inputProps
}) => {
  return (
    <div className={` ${className}`}>
      <label className="text-neutral-950 block text-sm font-medium">
        {label}
        {description && (
          <p className="mt-2 text-sm font-normal text-neutral-800">
            {description}
          </p>
        )}
        <div className="relative">
          <input
            {...inputProps}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
            className="border-1 search-cancel:appearance-none focus:border-neutral-950 relative mt-2 flex h-[48px] w-full appearance-none items-center justify-end rounded-[4px] border border-neutral-500 bg-white px-3 py-4 focus:border-0 focus:border-none focus:outline-neutral-900 focus:ring-offset-1 focus-visible:ring [&::-webkit-search-cancel-button]:hidden"
            type="search"
            tabIndex={0}
            placeholder={placeholder}
          />
          <div className="absolute right-0 top-4 pr-3">
            <Search className="h-4 w-4" />
          </div>
        </div>
      </label>
    </div>
  );
};

export default SearchInput;
