import { useCustomerInformation } from '~/hooks/useCustomerInformation';

type DisplayActiveCorporateProps = {
  className?: string;
  hideCustomerNo?: boolean;
};

export const DisplayActiveCorporate = ({
  className,
  hideCustomerNo,
}: DisplayActiveCorporateProps) => {
  const { customerInformation } = useCustomerInformation();
  return customerInformation ? (
    <div className={className}>
      <span>
        {customerInformation?.firstName} {customerInformation?.lastName}
      </span>
      {customerInformation?.customerType === 'business' &&
        !hideCustomerNo &&
        ` ${customerInformation?.customerNo}`}
    </div>
  ) : (
    <></>
  );
};
