import {
  Bankcard,
  Boxes,
  Car,
  WayPoint,
  Gift,
  SyncAltDotted,
  DocumentChecked,
  Document,
  Customer,
  MapPinned,
} from '@oresundsbron/icons';
import { useTranslation } from 'next-i18next';
import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';
import { getCustomerType } from '~/stores/auth';

interface SideNavigationLink {
  title: string;
  href: string;
  icon: ReactNode;
  key: string;
}

export function useSideNavigation(): SideNavigationLink[] {
  const { t } = useTranslation('account');
  const { locale } = useRouter();
  const customerType = getCustomerType();

  const businessCommuterFilter = (link: SideNavigationLink) =>
    link.key === 'trips' || link.key === 'recognitions';
  const privateFilter = (link: SideNavigationLink) => {
    return link.key !== 'commuters' && link.key !== 'kmtoll';
  };
  const businessFilter = () => true;

  const appliedFilter = useMemo(() => {
    if (customerType === 'business') {
      return businessFilter;
    } else if (customerType === 'business-commuter') {
      return businessCommuterFilter;
    } else {
      return privateFilter;
    }
  }, [customerType]);

  const iconColor = 'text-inherit';

  return useMemo(
    () =>
      [
        {
          title: t('navigation.dashboard'),
          href: `/${locale}/account/dashboard`,
          icon: (
            <Boxes
              width="1em"
              height="1em"
              className={`${iconColor}`}
              color="inherit"
            />
          ),
          key: 'dashboard',
        },
        {
          title: t('navigation.customerInformation'),
          href: `/${locale}/account/customerInformation`,
          icon: (
            <Customer
              width="1em"
              height="1em"
              className={`${iconColor}`}
              color="inherit"
            />
          ),
          key: 'customerInformation',
        },
        {
          title: t('navigation.agreement'),
          href: `/${locale}/account/agreement`,
          icon: (
            <DocumentChecked
              width="1em"
              height="1em"
              className={`${iconColor}`}
              color="inherit"
            />
          ),
          key: 'agreement',
        },
        {
          title: t('navigation.recognitions'),
          href: `/${locale}/account/vehicles`,
          icon: <Car width="1em" height="1em" className={`${iconColor}`} />,
          key: 'recognitions',
        },
        {
          title: t('navigation.commuters'),
          href: `/${locale}/account/commuters`,
          icon: (
            <SyncAltDotted
              width="1em"
              height="1em"
              className={`${iconColor}`}
            />
          ),
          key: 'commuters',
        },
        {
          title: t('navigation.trips'),
          href: `/${locale}/account/trips`,
          icon: (
            <WayPoint width="1em" height="1em" className={`${iconColor}`} />
          ),
          key: 'trips',
        },
        {
          title: t('navigation.invoices'),
          href: `/${locale}/account/invoices`,
          icon: (
            <Document width="1em" height="1em" className={`${iconColor}`} />
          ),
          key: 'invoices',
        },
        {
          title: t('navigation.debitCard'),
          href: `/${locale}/account/debit-card`,
          icon: (
            <Bankcard width="1em" height="1em" className={`${iconColor}`} />
          ),
          key: 'debitCard',
        },
        {
          title: t('navigation.roadTaxDenmark'),
          href: `/${locale}/account/bizz`,
          icon: (
            <MapPinned width="1em" height="1em" className={`${iconColor}`} />
          ),
          key: 'kmtoll',
        },
      ].filter(appliedFilter) as SideNavigationLink[],
    [t, locale, appliedFilter]
  );
}
