import { DisplayBizzStatus } from '~/modules/account/KmToll/DisplayBizzStatus';

export const showAllValue = 'showAll';
export interface FilterInput<T> {
  value: T;
  label: string;
}

export type ExtendedFilterInputs<T> =
  | FilterInput<T>
  | FilterInput<typeof showAllValue>;
interface DefaultOptionProps<T> {
  option: ExtendedFilterInputs<T>;
  selectedOptions: ExtendedFilterInputs<T>[];
  mode?: InputMode;
  handleOptionClick: (
    event: React.ChangeEvent<HTMLInputElement> | React.KeyboardEvent,
    option: ExtendedFilterInputs<T>
  ) => void;
  handleCheckBox: (
    event: React.KeyboardEvent,
    option: ExtendedFilterInputs<T>
  ) => void;
}

export type InputMode = 'default' | 'bizzStatus';

export function FilterInputOption<T extends string>({
  option,
  selectedOptions,
  handleOptionClick,
  handleCheckBox,
  mode,
}: DefaultOptionProps<T>) {
  return (
    <div
      key={option.value}
      className="flex items-center gap-3 border-b-2 px-3 py-4 last:border-b-0 "
    >
      <label className="flex items-center gap-3">
        <input
          className="border-1 hover:ring-inset-2 hover:border-1 h-[18px] w-[18px] flex-shrink-0 cursor-pointer appearance-none rounded-[4px] border border-neutral-500 checked:border-2 checked:border-white checked:bg-blue-800 checked:ring-2 checked:ring-blue-400 hover:border-blue-500 hover:checked:border-white "
          type="checkbox"
          checked={selectedOptions.some(
            (selectedOption) => selectedOption.value === option.value
          )}
          onChange={(event) => handleOptionClick(event, option)}
          onKeyDown={(event) => handleCheckBox(event, option)}
        />
        {mode === 'bizzStatus' && option.value !== showAllValue ? (
          <DisplayBizzStatus status={option.value} />
        ) : (
          option.label
        )}
      </label>
    </div>
  );
}
