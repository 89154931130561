import {
  FC,
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

export type MultiStepContext = {
  currentStep: number;
  steps: number;
  setSteps: (n: number) => void;
  isOnLastStep: boolean;
  nextStep: () => void;
  close: () => void;
  prevStep: () => void;
};

const MultiStepContext = createContext<MultiStepContext>({
  currentStep: 0,
  isOnLastStep: true,
  nextStep: () => {},
  close: () => {},
  steps: 0,
  setSteps: () => {},
  prevStep: () => {},
});

export function useMultiStepModalContext() {
  return useContext(MultiStepContext);
}

export type MutliStepProviderProps = {
  onClose?: () => void;
};

export const MultiStepProvider: FC<
  PropsWithChildren<MutliStepProviderProps>
> = ({ onClose, children }) => {
  const [currentStep, setCurrentStep] = useState(() => 0);
  const [steps, setSteps] = useState(() => 0);

  const close = useCallback(() => {
    onClose && onClose();
    setCurrentStep(0);
  }, [onClose, setCurrentStep]);

  const isOnLastStep = useMemo(
    () => currentStep === steps - 1,
    [currentStep, steps]
  );

  const nextStep = useCallback(() => {
    if (isOnLastStep) {
      return close();
    }

    return setCurrentStep((prev) => prev + 1);
  }, [isOnLastStep, setCurrentStep, close]);

  const prevStep = useCallback(() => {
    return setCurrentStep((prev) => prev - 1);
  }, [setCurrentStep]);

  return (
    <MultiStepContext.Provider
      value={{
        currentStep,
        isOnLastStep,
        steps,
        setSteps,
        close,
        nextStep,
        prevStep,
      }}
    >
      {children}
    </MultiStepContext.Provider>
  );
};
