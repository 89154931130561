import { Banner, Box } from '@oresundsbron/bridge-ui';
import { FC, PropsWithChildren, Suspense } from 'react';
import SideNavigation from '../SideNavigation';
import PageMetadata from './PageMetadata';
import ErrorBoundary from '../../ErrorBoundary';
import { Loading } from './Loading';
import { useTranslation } from 'next-i18next';
import { ParentBreadcrumb } from './AccountBreadcrumbs';
import { cx } from 'class-variance-authority';
import { LoadingSpinner } from '~/components/LoadingSpinner';

export interface AccountContentProps {
  namespace: string;
  hideNavigation?: true;
  hideMetadata?: true;
  parentBreadcrumb?: ParentBreadcrumb[];
  queryNamespace?: boolean;
}

const AccountContent: FC<PropsWithChildren<AccountContentProps>> = ({
  namespace,
  hideMetadata,
  hideNavigation,
  children,
}) => {
  const { t } = useTranslation(['account', 'common'], {
    nsMode: 'fallback',
  });

  return (
    <ErrorBoundary
      fallback={
        <Banner className="col-span-3" color="negative">
          {t('errors.generic')}
        </Banner>
      }
    >
      <Suspense
        fallback={
          <Box className="col-span-3 flex items-center justify-center bg-neutral-100">
            <LoadingSpinner />
          </Box>
        }
      >
        <Box className="col-start-2 flex min-h-full md:gap-14">
          <Box
            className={cx(
              'w-[25%]',
              'hidden',
              !hideNavigation ? 'md:block' : undefined
            )}
          >
            <SideNavigation />
          </Box>
          <Box
            className={cx(
              'h-full w-full bg-white py-4 md:px-14 md:py-5',
              !hideNavigation ? 'md:w-[75%]' : undefined
            )}
          >
            <PageMetadata
              namespace={namespace}
              className={hideMetadata ? 'hidden' : undefined}
            />
            {children}
          </Box>
        </Box>
      </Suspense>
    </ErrorBoundary>
  );
};

export default AccountContent;
