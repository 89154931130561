import { Box, Button, Typography } from '@oresundsbron/bridge-ui';
import { Logout } from '@oresundsbron/icons';
import { useTranslation } from 'next-i18next';
import { FC } from 'react';
import { useSideNavigation } from '../../../hooks/useSideNavigation';
import { Root, Item, List } from '@radix-ui/react-navigation-menu';
import { useLogout } from '../../../hooks/useLogout';
import { useRouter } from 'next/router';
import { DefaultLink } from '~/components/Links/DefaultLink';
import { CorporateGroupSideMenu } from '~/modules/account/CorporateGroup/SideMenu/CoporateGroupSideMenu';
import { useCorporateGroup } from '~/modules/account/CorporateGroup/useCorporateGroup';

const SideNavigation: FC = () => {
  const { t } = useTranslation(['account', 'common'], {
    nsMode: 'fallback',
  });
  const router = useRouter();
  const links = useSideNavigation();
  const logout = useLogout();
  const { corporateGroup } = useCorporateGroup();

  return (
    <Box as="nav" className="h-full pt-4">
      {corporateGroup.length > 0 && <CorporateGroupSideMenu />}
      <Typography as="h3" intent="title" size="sm">
        {t('account.navigate')}
      </Typography>
      <Root
        value={router.asPath}
        defaultValue={router.asPath}
        orientation="vertical"
        className="mt-2"
        aria-labelledby="#account-navigation-heading"
      >
        <List>
          {links.map(({ href, icon, title, key }) => {
            const active = `/${router.locale}${router.asPath}`.includes(href);
            return (
              <Item
                value={href}
                className="text-black-900 flex font-medium"
                key={key}
              >
                <DefaultLink
                  href={href}
                  className="flex w-full gap-2 rounded py-3 pl-2 pr-4 text-neutral-900 data-[active]:bg-neutral-200 data-[active]:font-medium"
                  active={active}
                  navLinkWrapper
                  underline={false}
                >
                  <div
                    className={`flex h-full items-center ${
                      active ? 'text-neutral-950' : 'text-neutral-700'
                    } `}
                  >
                    {icon}
                  </div>
                  {title}
                </DefaultLink>
              </Item>
            );
          })}
        </List>
      </Root>
      <Box className="my-2 h-[1px] bg-neutral-400" />
      <Button
        intent="text"
        startIcon={<Logout fontSize="1.56rem" />}
        className="text-primary-900 pl-0"
        onClick={logout}
      >
        {t('action.logout')}
      </Button>
    </Box>
  );
};

export default SideNavigation;
