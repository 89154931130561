import { Button, Card, Typography } from '@oresundsbron/bridge-ui';
import { Close } from '@oresundsbron/icons';
import * as Dialog from '@radix-ui/react-dialog';
import { cx } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import {
  ElementType,
  FC,
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { Alert, AlertProps } from '../Alert';

type WithTrigger = {
  trigger: ReactNode;
  open?: never;
};

type WithoutTrigger = {
  trigger?: never;
  open: boolean;
};

type WithOrWithoutTrigger = WithTrigger | WithoutTrigger;

type Props = {
  className?: string;
  title: ReactNode;
  footer?: (close: () => void) => ReactNode;
  onOpenChange?: (open: boolean) => void;
  defaultOpen?: boolean;
  preventClose?: Pick<
    AlertProps,
    'title' | 'content' | 'cancelText' | 'confirmColor' | 'confirmText'
  >;
  contentWrapper?: ElementType;
} & WithOrWithoutTrigger;

export type ModalProps = PropsWithChildren<Props>;
export type ModalHandle = {
  close: () => void;
};

export const Modal = forwardRef<ModalHandle, ModalProps>(function Modal(
  {
    className,
    title,
    footer,
    children,
    trigger,
    open,
    defaultOpen,
    onOpenChange,
    preventClose,
    contentWrapper: ContentWrapper,
  },
  ref
) {
  const closeRef = useRef<HTMLButtonElement>(null);
  const preventRef = useRef(false);
  const [internalOpen, toggleInternalOpen] = useState<boolean>(
    () => open || defaultOpen || false
  );
  const [openAlert, toggleOpenAlert] = useState(false);

  const close = useCallback(() => {
    closeRef.current && closeRef.current.click();
  }, []);

  useImperativeHandle(ref, () => ({
    close,
  }));

  useEffect(() => {
    if (open !== undefined) {
      toggleInternalOpen(open);
    }
  }, [open]);

  useEffect(() => {
    if (preventClose) {
      preventRef.current = true;
    } else {
      preventRef.current = false;
    }
  }, [preventClose]);

  const { t } = useTranslation('common');
  return (
    <>
      <Dialog.Root
        onOpenChange={(open: boolean) => {
          if (!open && preventRef.current) {
            toggleOpenAlert(true);
            return;
          }
          onOpenChange && onOpenChange(open);
          toggleInternalOpen(open);
        }}
        open={internalOpen}
      >
        {trigger ? (
          <Dialog.Trigger asChild onClick={() => toggleInternalOpen(true)}>
            {trigger}
          </Dialog.Trigger>
        ) : (
          <Dialog.Trigger />
        )}
        <Dialog.Portal>
          <Dialog.Overlay className="fixed inset-0 z-40 grid place-content-center overflow-y-auto bg-black/75">
            <Card.Root
              as={Dialog.Content}
              className={cx(
                'max-h-screen w-[100vw] max-w-3xl overflow-y-auto p-6 shadow-md sm:my-4 sm:h-min sm:rounded-2xl',
                className
              )}
              rounded={false}
            >
              <div className=" flex justify-between">
                <Typography as="h2" intent="title" className="text-xl">
                  {title}
                </Typography>
                <Dialog.Close asChild>
                  <Button
                    intent="text"
                    aria-label={t('action.close')}
                    startIcon={<Close />}
                    color="primary"
                    iconSize="lg"
                    ref={closeRef}
                  />
                </Dialog.Close>
              </div>
              {ContentWrapper ? (
                <ContentWrapper>
                  {children}
                  {!!footer ? <Card.Footer>{footer(close)}</Card.Footer> : null}
                </ContentWrapper>
              ) : (
                <>
                  {children}
                  {!!footer ? <Card.Footer>{footer(close)}</Card.Footer> : null}
                </>
              )}
            </Card.Root>
          </Dialog.Overlay>
        </Dialog.Portal>
      </Dialog.Root>
      {preventClose ? (
        <Alert
          open={openAlert}
          onOpenChange={toggleOpenAlert}
          {...preventClose}
          onAction={(confirm) => {
            if (confirm && closeRef.current) {
              preventRef.current = false;
              setTimeout(() => {
                close();
              }, 100);
            }
          }}
        />
      ) : null}
    </>
  );
});
