import { Box, Typography } from '@oresundsbron/bridge-ui';
import { cx } from 'class-variance-authority';
import { useTranslation } from 'next-i18next';
import { FC, useMemo } from 'react';
import { Eq as StringEq } from 'fp-ts/lib/string';
import { useRouter } from 'next/router';

interface PageMetadataProps {
  namespace: string;
  className?: string;
  queryNamespace?: boolean;
}

const PageMetadata: FC<PageMetadataProps> = ({
  namespace,
  className,
  queryNamespace,
}) => {
  const { t } = useTranslation([namespace, 'account', 'common'], {
    nsMode: 'fallback',
  });

  const hasDescription = useMemo(
    () =>
      !StringEq.equals(
        t(`${namespace}.layout.description`),
        `${namespace}.layout.description`
      ),
    [namespace, t]
  );

  const { query } = useRouter();

  return (
    <Box className={cx(['pb-6 pt-12', className])}>
      <Typography as="h2" intent="headline" size="lg">
        {t(`${namespace}.layout.title`)}{' '}
        {queryNamespace ? query[namespace] : ''}
      </Typography>
      <Typography
        intent="body"
        className={!hasDescription ? 'hidden' : undefined}
      >
        {t(`${namespace}.layout.description`)}
      </Typography>
    </Box>
  );
};

export default PageMetadata;
