import { BackendError } from '@oresundsbron/api';
import { identity, pipe } from 'fp-ts/lib/function';
import { fromPredicate, match } from 'fp-ts/lib/Option';
import { Component, ErrorInfo, PropsWithChildren, ReactNode } from 'react';

type FallbackComponent = ReactNode;
type FallbackCallback = (error: BackendError) => ReactNode | null;

interface FallbackComponentProps {
  fallback: FallbackComponent;
  fallbackCallback?: never;
}

interface FallbackCallbackProps {
  fallback?: never;
  fallbackCallback: FallbackCallback;
}

type Props = FallbackComponentProps | FallbackCallbackProps;

interface State {
  error?: BackendError;
}

class ErrorBoundary extends Component<PropsWithChildren<Props>, State> {
  constructor(props: Props) {
    super(props);
    this.state = { error: undefined };
  }

  static getDerivedStateFromError(error: BackendError) {
    return { error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can use your own error logging service here
    console.log({ error, errorInfo });
  }

  render() {
    if (this.state.error) {
      return pipe(
        this.props.fallback,
        fromPredicate((fbc): fbc is ReactNode => !!fbc),
        match(() => this.props.fallbackCallback!(this.state.error!), identity)
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
