import * as AlertDialog from '@radix-ui/react-alert-dialog';
import { FC, ReactNode } from 'react';
import { Button, ButtonProps, Card, Typography } from '@oresundsbron/bridge-ui';
import { cx } from 'class-variance-authority';

type WithTrigger = {
  trigger: ReactNode;
  open?: never;
};

type WithoutTrigger = {
  trigger?: never;
  open: boolean;
};

type WithOrWithoutTrigger = WithTrigger | WithoutTrigger;

export type AlertProps = {
  className?: string;
  title: ReactNode;
  content: ReactNode;
  cancelText: string;
  confirmText: string;
  confirmColor: ButtonProps['color'];
  onAction: (confirm: boolean) => void;
  onOpenChange?: (open: boolean) => void;
} & WithOrWithoutTrigger;

export const Alert: FC<AlertProps> = ({
  open,
  className,
  onOpenChange,
  trigger,
  title,
  content,
  cancelText,
  confirmText,
  onAction,
  confirmColor = 'negative',
}) => (
  <AlertDialog.Root open={open} onOpenChange={onOpenChange}>
    {trigger ? (
      <AlertDialog.Trigger asChild>{trigger}</AlertDialog.Trigger>
    ) : (
      <AlertDialog.Trigger />
    )}
    <AlertDialog.Portal>
      <AlertDialog.Overlay
        className="fixed inset-0 z-40 bg-black/75"
        forceMount
      />
      <AlertDialog.Content asChild>
        <Card.Root
          className={cx(
            'fixed inset-1/2 z-40 h-screen w-[100vw] max-w-lg translate-x-[-50%] translate-y-[-50%] sm:h-min sm:rounded-2xl',
            className
          )}
          rounded={false}
        >
          <Card.Header>
            <AlertDialog.Title asChild>
              <Typography intent="title">{title}</Typography>
            </AlertDialog.Title>
          </Card.Header>
          <Card.Content>
            <AlertDialog.Description asChild>
              <Typography>{content}</Typography>
            </AlertDialog.Description>
          </Card.Content>
          <Card.Footer
            style={{ display: 'flex', gap: 25, justifyContent: 'flex-end' }}
          >
            <AlertDialog.Cancel asChild>
              <Button
                intent="text"
                color="neutral"
                onClick={() => onAction(false)}
              >
                {cancelText}
              </Button>
            </AlertDialog.Cancel>
            <AlertDialog.Action asChild>
              <Button color={confirmColor} onClick={() => onAction(true)}>
                {confirmText}
              </Button>
            </AlertDialog.Action>
          </Card.Footer>
        </Card.Root>
      </AlertDialog.Content>
    </AlertDialog.Portal>
  </AlertDialog.Root>
);
