import { useTheme } from '@emotion/react';
import { Theme, Tokens } from '@oresundsbron/theme';
import { useEffect, useState } from 'react';

interface WindowSize {
  isMobile: boolean;
  isTablet: boolean;
  isDesktop: boolean;
}

export function useWindowSize() {
  const { tokens } = useTheme() as unknown as Theme<Tokens>;
  const [match, setMatch] = useState<WindowSize>({
    isMobile: false,
    isTablet: false,
    isDesktop: false,
  });

  useEffect(() => {
    function handleResize() {
      setMatch({
        isMobile: window.matchMedia(`screen and (max-width: 639px)`).matches,
        isTablet: window.matchMedia(
          `screen and (min-width: ${tokens?.breakpoints.sm})`
        ).matches,
        isDesktop: window.matchMedia(`screen and (max-width: 1200px)`).matches,
      });
    }

    window.addEventListener('resize', handleResize);
    handleResize();

    return () => window.removeEventListener('resize', handleResize);
  }, [setMatch, tokens]);

  return match;
}
