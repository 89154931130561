import {
  ThumbsUp,
  InfoPay,
  ErrorNotFilled,
  MessageInfo,
  Close,
} from '@oresundsbron/icons';
import { PropsWithChildren, useState } from 'react';

interface UserFeedBackProps {
  colorMode: 'information' | 'success' | 'warning' | 'error';
  dismiss?: boolean;
  title?: string;
  size?: 'big' | 'small';
  className?: string;
}

const UserFeedBack = ({
  colorMode,
  children,
  dismiss = false,
  size = 'small',
  className,
  title,
}: PropsWithChildren<UserFeedBackProps>) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleCloseClick = () => {
    setIsVisible(false);
  };

  if (!isVisible) {
    return null;
  }

  const getUserFeedBackColorClasses = (
    colorMode: UserFeedBackProps['colorMode'] = 'information'
  ) => {
    switch (colorMode) {
      case 'information':
        return 'bg-blue-100 text-blue-800 ';
      case 'success':
        return 'bg-green-100 text-green-800 ';
      case 'warning':
        return 'bg-yellow-100 text-yellow-800 ';
      case 'error':
        return 'bg-red-100 text-red-800';
      default:
    }
  };

  const getSizeClasses = (size: UserFeedBackProps['size']) => {
    switch (size) {
      case 'big':
        return {
          container: 'gap-8 ',
          textAndTitle: 'text-base flex flex-col gap-4',
          iconSize: 48,
          titleSize: 'text-[30px]',
          padding: 'p-8',
        };
      default:
        return {
          container: 'gap-4',
          textAndTitle: 'text-sm',
          iconSize: 16,
          titleSize: '',
          padding: 'p-4',
        };
    }
  };

  const getIcon = (
    iconMode: UserFeedBackProps['colorMode'] = 'information'
  ) => {
    switch (iconMode) {
      case 'information':
        return (
          <InfoPay width={sizeClasses.iconSize} height={sizeClasses.iconSize} />
        );
      case 'success':
        return (
          <ThumbsUp
            width={sizeClasses.iconSize}
            height={sizeClasses.iconSize}
          />
        );
      case 'warning':
        return (
          <MessageInfo
            width={sizeClasses.iconSize}
            height={sizeClasses.iconSize}
          />
        );
      case 'error':
        return (
          <ErrorNotFilled
            width={sizeClasses.iconSize}
            height={sizeClasses.iconSize}
          />
        );
    }
  };

  const sizeClasses = getSizeClasses(size);
  const userFeedBackColorClasses = getUserFeedBackColorClasses(colorMode);
  const Icon = getIcon(colorMode);

  return (
    <>
      <div
        className={`flex justify-between ${sizeClasses.padding} )} 
        rounded-xl  ${userFeedBackColorClasses} ${sizeClasses.container} ${className}`}
      >
        <div className={`flex gap-4 ${sizeClasses.container}`}>
          <div>{Icon}</div>
          <div className={`max-w-prose  ${sizeClasses.textAndTitle}`}>
            <span className={`font-semibold ${sizeClasses.titleSize}`}>
              {title}{' '}
            </span>
            {children}
          </div>
        </div>
        {dismiss ? (
          <div>
            <Close
              height={16}
              width={16}
              className="cursor-pointer self-end"
              onClick={handleCloseClick}
            />
          </div>
        ) : null}
      </div>
    </>
  );
};

export default UserFeedBack;
